import { useState, useEffect } from 'react'

const useDropdown = (ref) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdownOpen = () => { setDropdownOpen(!dropdownOpen) }

  const handleDropdownClick = e => {
    if (!dropdownOpen) { return }

    if (ref.current && !ref.current.contains(e.target)) { toggleDropdownOpen() }
  }

  useEffect(() => {
    document.addEventListener('click', handleDropdownClick)

    return () => {
      document.removeEventListener('click', handleDropdownClick)
    }
  })

  return {
    dropdownOpen,
    setDropdownOpen
  }
}


export default useDropdown
