import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import useDropdown from '@/hooks/useDropdown'
import useQuery from '@/hooks/useQuery'
import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Modal from '@/shared/Modal'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const MarkAllAsReadButton = () => {
  const { openModal, closeModal, isOpen } = useModal()
  const [, setToast] = useGlobalState('toast')
  const [notificationCounts, setNotificationsCounts] = useGlobalState('notificationCounts')
  const { putpostRequest } = useQuery()
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)

  const markAllAsRead = () => {
    closeModal()
    putpostRequest('/api/v3/posts/mark_all_as_read', 'POST', {}, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setNotificationsCounts({ ...notificationCounts, ...{ messages: 0 } })
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <b className="text-sm font-medium dark:text-white text-gray-800">Marked all as read</b>
        <p className="mt-1 text-sm dark:text-gray-300 text-gray-500">Just a thought. Can you unread unreads? Is that possible?</p>
      </div>)
      }
    )
  }
  const openConfirmModal = () => {
    setDropdownOpen(false)
    openModal()
  }

  return <>
    <div className='relative flex items-center px-2 dark:text-white'>
      <button onClick={() => setDropdownOpen(!dropdownOpen)} className=''>
        <DotsHorizontalIcon className="h-4 w-4" aria-hidden="true" />
        <span className="sr-only" hidden>Open settings</span>
      </button>
      { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute left-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
        <div onClick={openConfirmModal} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt whitespace-nowrap' >Mark All As Read</div>
        <Link to='/settings' className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt whitespace-nowrap' >Message Settings</Link>
      </div> }
    </div>
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Really mark all of these as read?</h2>

        <div className='p-2'>I mean, really do it?</div>
        <div className='flex flex-col space-y-3'>
          <PrimaryButton onClick={markAllAsRead} text='Obey me, computer. Mark them as read!' />
          <DefaultButton onClick={closeModal} text='I will reflect further upon these unread messages.' />
        </div>
      </div>
    </Modal>
  </>
}

export default MarkAllAsReadButton
