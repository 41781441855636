import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from '@/state'

const defaultEduAds = [
  {
    name: 'Voice Acting 101',
    imageUrl: 'https://clc-uploads-prod.s3.amazonaws.com/image_uploads/64/64va101.png'
  },
  {
    name: 'Audio Engineering for Voice Actors',
    imageUrl: 'https://clc-uploads-prod.s3.amazonaws.com/image_uploads/56/56tony_ae4va.png'
  }
]

const ProjectAds = (props) => {
  return <>
    <div className='flex flex-col gap-y-1'>
      <SidebarEducationAd />
    </div>
  </>
}

const MobileEducationAd = () => {
  const [eduAds] = useGlobalState('eduAds')
  const [currentUser] = useGlobalState('currentUser')
  const ads = eduAds?.length > 0 ? eduAds : defaultEduAds

  if (ads.length === 0) { return null }

  return (
    <div id='ezoic-sidebar-ads' className='sm:hidden w-full flex flex-col gap-y-2'>
      <div className='bg-cccblue text-white text-xs p-1 flex flex-col gap-y-2 justify-center items-center text-center border border-1 dark:border-white border-gray-900'>
        <h6 className='text-center mb-1 font-extrabold dark:text-white'>Now Enrolling </h6>
        <div className='flex flex gap-x-1'>
          <div className='w-36'>
            <div className="aspect-w-16 aspect-h-9">
              <a href={`https://www.closingcredits.com/courses/voice-acting-101?cccu=${currentUser?.id}`}>
                <img className="object-cover w-full rounded-lg border border-2 border-white" src={`https://images.closingcredits.com/image_uploads/64/64va101.png?aspect=16:9&width=200`} alt={'Voice Acting 101'} />
              </a>
            </div>
          </div>

          { ads.map((ad, i) => { 
            if (i === 0) { return <div key={`eduad${ad.name}`} className='w-36'>
              <div className="aspect-w-16 aspect-h-9">
                <a href={`https://www.closingcredits.com/courses/${ad.remoteUrl}?cccu=${currentUser?.id}`}>
                  <img className="object-cover w-full rounded-lg border border-2 border-white" src={`${ad.imageUrl}?aspect=16:9&width=200`} alt={ad.name} />
                </a>
              </div>
            </div>
          }}) }
        </div>
        <a href='https://www.castingcall.club/links?march=clc' className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple to-cccorange hover:from-cccorange hover:to-cccpurple" >
          <span>Reserve Your Seat</span>
        </a>
      </div>
    </div>
  )
}

const SidebarEducationAd = () => {
  const [eduAds] = useGlobalState('eduAds')
  const [currentUser] = useGlobalState('currentUser')
  const ads = eduAds?.length > 0 ? eduAds : defaultEduAds

  if (ads.length === 0) { return null }

  console.log(ads)

  return (
    <div id='ezoic-sidebar-ads' className='hidden xl:block min-w-96 max-w-96 flex flex-col gap-y-2'>
      <div className='bg-cccblue text-white text-xs p-1 flex flex-col gap-y-2 justify-center items-center text-center border border-1 dark:border-white border-gray-900'>
        <h6 className='text-center mb-1 font-extrabold dark:text-white'>Now Enrolling </h6>
        <div className='flex flex-col gap-y-1'>
          <div className='w-full sm:w-72'>
            <div className="aspect-w-16 aspect-h-9">
              <a href={`https://www.closingcredits.com/courses/voice-acting-101?cccu=${currentUser?.id}`}>
                <img className="object-cover w-full rounded-lg border border-2 border-white" src={`https://images.closingcredits.com/image_uploads/64/64va101.png?aspect=16:9&width=420`} alt={'Voice Acting 101'} />
              </a>
            </div>
          </div>
          { ads.map((ad, i) => <div key={`sidebareduad${ad.name}`} className='w-full sm:w-72'>
            <div className="aspect-w-16 aspect-h-9">
              <a href={`https://www.closingcredits.com/courses/${ad.remoteUrl}?cccu=${currentUser?.id}`}>
                <img className="object-cover w-full rounded-lg border border-2 border-white" src={`${ad.imageUrl}?aspect=16:9&width=420`} alt={ad.name} />
              </a>
            </div>
          </div>)}
        </div>
        <a href='https://www.castingcall.club/links?march=clc' className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-cccpurple to-cccorange hover:from-cccorange hover:to-cccpurple" >
          <span>Reserve Your Seat</span>
        </a>
      </div>
    </div>
  )
}

const SidebarAd = ({ adID }) => {
  const [currentUser] = useGlobalState('currentUser')

  useEffect(() => {
    window.ezstandalone = window.ezstandalone || {}
    window.ezstandalone.cmd = window.ezstandalone.cmd || []
    window.ezstandalone.cmd.push(function() {
      window.ezstandalone?.displayMore(adID)
      window.ezstandalone?.enable()
      window.ezstandalone?.display()
      window.ezstandalone?.refresh()
    })
    return () => {
      window.ezstandalone = window.ezstandalone || {}
      window.ezstandalone.cmd = window.ezstandalone.cmd || []
      window.ezstandalone.cmd.push(function() {
        window.ezstandalone?.destroyPlaceholders(adID)
      })
    }
  }, [])

  return <>
    { currentUser?.shouldSeeAds && <div id='ezoic-sidebar-ads' className='hidden xl:block min-w-96 max-w-96 flex flex-col gap-y-2'>
    <div className='bg-cccpurple text-white text-xs p-1 flex flex-col gap-y-2 justify-center items-center text-center border border-1 dark:border-white border-gray-900'>
      <img src='https://ddppjbdexhxzj.cloudfront.net/ccc-premium.svg' className='w-48' />
      <span>Get ad-free experience with special benefits and directly support CCC.</span>
      <a href='/pricing' className="inline-flex justify-center px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccblue to-cccorange hover:from-cccorange hover:to-cccblue hover:text-white"> Get CCC Premium </a>
    </div>
    <div className='w-96' id={`ezoic-pub-ad-placeholder-${adID}`} />
  </div> }
  </>
}

const MobileAd = ({ adID }) => {
  const [currentUser] = useGlobalState('currentUser')

  useEffect(() => {
    window.ezstandalone = window.ezstandalone || {}
    window.ezstandalone.cmd = window.ezstandalone.cmd || []
    window.ezstandalone.cmd.push(function() {
        window.ezstandalone?.displayMore(adID)
        window.ezstandalone?.enable()
        window.ezstandalone?.display()
        window.ezstandalone?.refresh()
    })
    return () => {
      window.ezstandalone = window.ezstandalone || {}
      window.ezstandalone.cmd = window.ezstandalone.cmd || []
      window.ezstandalone.cmd.push(function() {
        window.ezstandalone?.destroyPlaceholders(adID)
      })
    }
  }, [])

  return <>
    {currentUser?.shouldSeeAds && <div id='ezoic-mobile-ad' className='sm:hidden w-full flex flex-col gap-y-2'>
    <div className='bg-cccpurple text-white text-xs p-1 flex flex-col gap-y-2 justify-center items-center text-center border border-1 dark:border-white border-gray-900'>
      <img src='https://ddppjbdexhxzj.cloudfront.net/ccc-premium.svg' className='w-48' />
      <span>Get ad-free experience with special benefits and directly support CCC.</span>
      <a href='/pricing' className="inline-flex justify-center px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md bg-gradient-to-r from-cccblue to-cccorange hover:from-cccorange hover:to-cccblue hover:text-white"> Get CCC Premium </a>
    </div>
    <div className='w-full mt-2 md:hidden'>
      <div id={`ezoic-pub-ad-placeholder-${adID}`} />
    </div>
  </div> }
  </>
}

MobileAd.propTypes = {
  adID: PropTypes.number.isRequired
}

SidebarAd.propTypes = {
  adID: PropTypes.number.isRequired
}

export { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd, ProjectAds }
