import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { FilterIcon } from '@heroicons/react/outline'

import useDropdown from '@/hooks/useDropdown'
import classNames from '@/utils/classNamesLocal'

const styles = {
  desktop: {
    selectedTab: 'border-cccblue text-cccblue dark:text-white dark:bg-cccblue',
    unselectedTab: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-white',
    defaultTab: 'whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm rounded-t-md cursor-pointer'
  },
  mobile: {
    selectedTab: 'border-cccblue text-cccblue dark:text-white dark:bg-cccblue',
    unselectedTab: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-white',
    defaultTab: 'block rounded-md pt-2 px-3 text-base font-medium cursor-pointer'
  }
}

const TabCount = ({ number, selected }) => {
  return <>
    <span className={classNames(
      selected ? 'bg-cccblue text-white dark:bg-white dark:text-cccblue' : 'bg-gray-200 text-gray-900',
      'ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}

const Tab = ({ value, name, view, count, status, changeTab }) => {
  const selected = status === value
  return (
    <div onClick={() => changeTab(value)} className={classNames(
        selected ? styles[view].selectedTab : styles[view].unselectedTab, styles[view].defaultTab
      )} aria-current={selected ? 'page' : undefined} aria-label={name} >
      {name}
      { typeof (count) !== 'undefined' && parseInt(count, 10) > 0 && <TabCount number={count} selected={selected} /> }
    </div>
  )
}

export default function Tabs({ tablist, currentTab, changeTab }) {
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  return (
    <>
      <div className="sm:divide-y sm:divide-gray-200">
        <div className="sm:border-b sm:border-gray-200">
          <nav className="hidden sm:py-2 sm:flex sm:space-x-4" aria-label="Global">
            { tablist.map((tab, idx) => <Tab
                key={`tabdesktop${tab.name}${idx}`}
                name={tab.name}
                changeTab={changeTab}
                status={currentTab}
                value={tab.changeValue}
                view='desktop'
                count={tab.count} />
            )}
          </nav>
        </div>
        <div className="inline-flex rounded-md shadow-sm sm:hidden relative">
          {/* Mobile menu button */}
          <button onClick={() => setDropdownOpen(!dropdownOpen)} className="py-1 px-2 sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
            <span className="sr-only" hidden>Open filter menu</span>
            <FilterIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
          </button>
          { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute left-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
            { tablist.map((tab, idx) => <Tab
                key={`tabmobile${tab.name}${idx}`}
                name={tab.name}
                changeTab={changeTab}
                status={currentTab}
                value={tab.changeValue}
                view='mobile'
                count={tab.count} />
            )}
          </div> }
        </div>
      </div>
    </>
  )
}

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  count: PropTypes.number,
  value: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired
}
Tabs.propTypes = {
  tablist: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired
}
TabCount.propTypes = {
  number: PropTypes.number.isRequired,
  selected: PropTypes.bool
}
