import React, { useState, useRef, useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd } from '@/Layout/Ads'
import Card from '@/shared/Card'
import { useGlobalState } from '@/state'
import classNames from '@/utils/classNamesLocal'
import useDropdown from '@/hooks/useDropdown'
import SavedSearchProjectModal from '@/shared/SavedSearchProjectModal'
import Loading from '@/shared/Loading'
import useQuery from '@/hooks/useQuery'
import SVGIcon from '@/components/icon'
import TagAutocomplete from '@/shared/TagAutocomplete'
import { CheckIcon, XIcon, CogIcon, StarIcon, ChevronUpIcon, ChevronDownIcon, GlobeAltIcon, FilmIcon, SortAscendingIcon, MicrophoneIcon } from '@heroicons/react/solid'

const talentKindHash = {
  'voice_actor': { image: 'voiceactor', text: 'Voice Actor', to_db: 'voice_actor' },
  'live_actor': { image: 'actor', text: 'Actor', to_db: 'live_actor' },
  'singer': { image: 'singer', text: 'Singer', to_db: 'singer' },
  'artist': { image: 'artist', text: 'Artist', to_db: 'artist' },
  'animator': { image: 'animator', text: 'Animator', to_db: 'animator' },
  'video_editor': { image: 'video-editor', text: 'Video Editor', to_db: 'video_editor' },
  'music_composer': { image: 'musiccomposer', text: 'Musician', to_db: 'music_composer' },
  'audio_engineer': { image: 'audio-engineer', text: 'Audio Eng', to_db: 'audio_engineer' },
  'writer': { image: 'writer', text: 'Writer', to_db: 'writer' },
  'director': { image: 'director', text: 'Director', to_db: 'director' },
  'agent': { image: 'agent', text: 'Agent', to_db: 'agent' },
  'producer': { image: 'producer', text: 'Producer', to_db: 'producer' }
}

const LabelPill = ({ kind, bgColor, text, preview }) => {
  const styles = `flex-shrink-0 inline-block flex px-2 mr-1 justify-center space-x-1 text-white bg-${bgColor} text-xs rounded-full`
  return <span className={preview ? 'flex text-xs mr-2 items-center text-gray-700 dark:text-gray-400' : styles}>
      {kind === 'lang' && <GlobeAltIcon className='h-4 w-4' /> }
      {kind === 'voice' && <MicrophoneIcon className='h-4 w-4' /> }
      {kind === 'setup' && <CogIcon className='h-4 w-4' /> }
      <span>{text}</span>
    </span>
}

const RoleSearchRow = ({ project, role }) => {
  const [currentUser] = useGlobalState('currentUser')
  const [expandedRole, setExpandedRole] = useState({})
  const [loading, setLoading] = useState(false)
  const { getRequest, createQueryString } = useQuery()
  const languages = role.searchLabels['user-languages'] || []
  const rawAccents = role.searchLabels['user-accents'] || []
  const accents = rawAccents.filter(item => item !== 'english')
  const hardwares = role.searchLabels['user-hardwares'] || []
  const softwares = role.searchLabels['user-softwares'] || []
  const genderAges = role.searchLabels['user-gender-age'] || []
  const demoCategories = role.searchLabels['demo-categories'] || []
  const setups = [...hardwares, ...softwares]
  const voices = [...genderAges, ...demoCategories]
  const skills = role.searchLabels['user-skills'] || []

  const imageName = talentKindHash[role.isA].image

  const expand = () => {
    if (expandedRole?.id) {
      setExpandedRole({})
      return
    }

    setLoading(true)
    getRequest('/api/v3/omnisearch/expand_role', { role_id: role.id }, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setExpandedRole(jsonData.expandedRole)
    })
  }

  return <div className={`my-1 px-4 py-1 ${expandedRole?.id ? `border-2 border-${project.bgColor}` : ''}`}>
    <div className="">
      <div className="flex items-start space-x-3">
        <div className="relative m-1">
          <img className={`h-10 w-10 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-${project.bgColor}`} src={role.imageUrl} alt={role.name} />
          <span className={`absolute -bottom-0.5 -right-1 bg-${project.bgColor} text-white rounded-tl px-0.5 py-px`}>
            <SVGIcon name={imageName} className='h-5 w-5' />
          </span>
        </div>
        <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
          <div className='flex flex-col truncate'>
            <div className='flex flex-col sm:flex-row justify-start gap-x-2 sm:items-center'>
              <div><span className={`px-2 text-xs rounded-md text-white capitalize ${role.payment === 'unpaid' ? 'bg-gray-500 dark:bg-gray-500' : 'bg-green-500'}`}>
                { role.paymentTotal === null && <>{role.payment}</>}
                { role.paymentTotal !== null && <span>Approx. Total Pay: <b>{role.paymentTotal}</b></span> }
              </span></div>

              <div className='flex items-center cursor-pointer hover:text-cccblue dark:text-white dark:hover:text-cccblue' onClick={expand}>
                <span className="font-medium" href={`/projects/${project.slug}`} rel="noreferrer">{role.name}</span>
                { expandedRole?.id && <ChevronUpIcon className='w-4 h-4' /> }
                { !expandedRole?.id && <ChevronDownIcon className='w-4 h-4' /> }
              </div>
            </div>

            { !expandedRole?.id && <>
              <div className='flex max-w-xl'>
                { languages.length > 0 && <div className='pb-1 flex justify-center items-center text-xs whitespace-nowrap'>
                  {languages.map((lang, idx) => (
                    <LabelPill key={`${idx}langs${role.id}`} preview={true} kind='lang' bgColor={project.bgColor} text={lang} />
                  ))}
                </div> }
                { accents.length > 0 && <div className='pb-1 flex justify-start items-center whitespace-nowrap'>
                  {accents.map((acc, idx) => (
                    <LabelPill key={`${idx}accs${role.id}`} preview={true} kind='voice' bgColor={project.bgColor} text={acc} />
                  ))}
                </div> }
                { voices.length > 0 && <div className='pb-1 flex justify-start items-center whitespace-nowrap'>
                  {voices.map((voice, idx) => (
                    <LabelPill key={`${idx}voiced${role.id}`} preview={true} kind='voice' bgColor={project.bgColor} text={voice} />
                  ))}
                </div> }
                { skills.length > 0 && <div className='pb-1 flex justify-start items-center whitespace-nowrap'>
                  {skills.map((skill, idx) => (
                    <LabelPill key={`${idx}skills${role.id}`} preview={true} kind='setup' bgColor={project.bgColor} text={skill} />
                  ))}
                </div> }
                { setups.length > 0 && <div className='pb-1 flex justify-start items-center whitespace-nowrap'>
                  {setups.map((setup, idx) => (
                    <LabelPill key={`${idx}setups${role.id}`} preview={true} kind='setup' bgColor={project.bgColor} text={setup} />
                  ))}
                </div> }
              </div>
            </> }
          </div>
        </div>
      </div>
    </div>
    { loading && <Loading /> }
    { expandedRole?.id && <>
      <div className='mt-1 flex flex-col text-sm text-gray-800 dark:text-gray-200'>
        <div className='flex flex-wrap gap-y-1'>
          { expandedRole.languages.length > 0 && <>
            {expandedRole.languages.map((lang, idx) => (
              <LabelPill key={`${idx}langs${role.id}`} kind='lang' bgColor={project.bgColor} text={lang} />
            ))}
          </> }
          { expandedRole.accents.length > 0 && <>
            {expandedRole.accents.map((acc, idx) => (
              <LabelPill key={`${idx}accs${role.id}`} kind='voice' bgColor={project.bgColor} text={acc} />
            ))}
          </> }
          { expandedRole.voices.length > 0 && <>
            {expandedRole.voices.map((voice, idx) => (
              <LabelPill key={`${idx}voiced${role.id}`} kind='voice' bgColor={project.bgColor} text={voice} />
            ))}
          </> }
          { expandedRole.skills.length > 0 && <>
            {expandedRole.skills.map((skill, idx) => (
              <LabelPill key={`${idx}skills${role.id}`} kind='setup' bgColor={project.bgColor} text={skill} />
            ))}
          </> }
          { expandedRole.setups.length > 0 && <>
            {expandedRole.setups.map((setup, idx) => (
              <LabelPill key={`${idx}setups${role.id}`} kind='setup' bgColor={project.bgColor} text={setup} />
            ))}
          </> }
        </div>
        <div className='prose-sm' dangerouslySetInnerHTML={{ __html: expandedRole.characteristics }} />
        { expandedRole.line1 && <div className='p-2 rounded flex flex-col text-gray-700 dark:text-gray-300'>
          <span>Audition line(s):</span>
          {expandedRole.line1 && <div>1: {expandedRole.line1}</div> }
          {expandedRole.line2 && <div>2: {expandedRole.line2}</div> }
          {expandedRole.line3 && <div>3: {expandedRole.line3}</div> }
        </div> }
      </div>
    </> }
  </div>
}

const arrayifyParams = (params, cleanFilters) => {
  if (!params) { return cleanFilters }

  const filters = Object.assign({}, cleanFilters)
  for (const item of params.split('|')) {
    if (item.includes('is:all')) { continue }
    if (item.includes('is:')) { filters.talentKind = item.replace('is:', '') }
    if (item.includes('co:')) {
      const filter = item.replace('co:', '')
      if (filters.contains.indexOf(filter) === -1) { filters.contains.push(filter) }
    }
    if (item.includes('nc:')) {
      const filter = item.replace('nc:', '')
      if (filters.notContains.indexOf(filter) === -1) { filters.notContains.push(filter) }
    }
    if (item.includes('user-gender-age:')) {
      const filter = item.replace('user-gender-age:', '')
      if (filters.genderAges.indexOf(filter) === -1) { filters.genderAges.push(filter) }
    }
    if (item.includes('user-languages:')) {
      const filter = item.replace('user-languages:', '')
      if (filters.languages.indexOf(filter) === -1) { filters.languages.push(filter) }
    }
    if (item.includes('user-accents:')) {
      const filter = item.replace('user-accents:', '')
      if (filters.accents.indexOf(filter) === -1) { filters.accents.push(filter) }
    }
    if (item.includes('user-skills:')) {
      const filter = item.replace('user-skills:', '')
      if (filters.skills.indexOf(filter) === -1) { filters.skills.push(filter) }
    }
    if (item.includes('user-softwares:')) {
      const filter = item.replace('user-softwares:', '')
      if (filters.softwares.indexOf(filter) === -1) { filters.softwares.push(filter) }
    }
    if (item.includes('user-hardwares:')) {
      const filter = item.replace('user-hardwares:', '')
      if (filters.hardwares.indexOf(filter) === -1) { filters.hardwares.push(filter) }
    }
    if (item.includes('user-interests:')) {
      const filter = item.replace('user-interests:', '')
      if (filters.interests.indexOf(filter) === -1) { filters.interests.push(filter) }
    }
    if (item.includes('demo-categories')) {
      const filter = item.replace('demo-categories:', '')
      if (filters.demoCategories.indexOf(filter) === -1) { filters.demoCategories.push(filter) }
    }
  }

  return filters
}

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

const SearchIndex = (props) => {
  const { initTalentKind, doNotAlterHistory, hideAdSidebar } = props
  const [endOfResults, setEndOfResults] = useState(false)
  const urlParams = new window.URLSearchParams(window.location.search)
  const { getRequest, createQueryString } = useQuery()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
  const [meta, setMeta] = useState({})
  const [payment, setPayment] = useState(urlParams.get('payment') || 'all')
  const [classification, setClassification] = useState(urlParams.get('cl') || 'all')
  const [order, setOrder] = useState(urlParams.get('order_by') || 'magic')
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const [page, setPage] = useState(1)

  const [resultState, setResultState] = useReducer(
    (resultState, newResultState) => (
      { ...resultState, ...newResultState }), { results: [], favorites: [], dismissals: [] }
    )
  const { results, favorites, dismissals } = resultState

  const cleanFilters = {
    talentKind: initTalentKind || 'all',
    contains: [],
    notContains: [],
    softwares: [],
    hardwares: [],
    interests: [],
    skills: [],
    languages: [],
    accents: [],
    genderAges: [],
    demoCategories: []
  }

  const orderMap = {
    updated_at: 'Latest Activity',
    listed_at: 'New',
    deadline: 'Deadline',
    magic: 'Literal Magic',
    popular: 'Popular'
  }

  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), arrayifyParams(urlParams.get('search'), cleanFilters)
    )
  const { hardwares, softwares, interests, skills, accents, languages, genderAges, demoCategories, talentKind, contains, notContains } = state

  const totalAppliedFilters = hardwares.length + softwares.length + interests.length + skills.length + accents.length + languages.length + genderAges.length + demoCategories.length + contains.length + notContains.length

  const clearFilters = () => {
    setPage(1)
    setOrder('deadline')
    setEndOfResults(false)
    setState({
      talentKind: 'all',
      contains: [],
      notContains: [],
      softwares: [],
      hardwares: [],
      interests: [],
      skills: [],
      languages: [],
      accents: [],
      genderAges: [],
      demoCategories: []
    })
  }

  const notContainKeyPress = (e) => { if (e.keyCode === 13) { addNotContains() } }
  const addNotContains = () => {
    const label = document.getElementById('not_contains').value
    document.getElementById('not_contains').value = null
    setState({ notContains: [...notContains, ...[label]] })
  }
  const containKeyPress = (e) => { if (e.keyCode === 13) { addContains() } }
  const addContains = () => {
    const label = document.getElementById('contains').value
    document.getElementById('contains').value = null
    setState({ contains: [...contains, ...[label]] })
  }

  const addLabel = (label, labelKind) => {
    if (labelKind === 'user-softwares') { setState({ softwares: [...softwares, ...[label]] }) }
    if (labelKind === 'user-hardwares') { setState({ hardwares: [...hardwares, ...[label]] }) }
    if (labelKind === 'user-interests') { setState({ interests: [...interests, ...[label]] }) }
    if (labelKind === 'user-skills') { setState({ skills: [...skills, ...[label]] }) }
    if (labelKind === 'user-languages') { setState({ languages: [...languages, ...[label]] }) }
    if (labelKind === 'user-accents') { setState({ accents: [...accents, ...[label]] }) }
    if (labelKind === 'user-gender-age') { setState({ genderAges: [...genderAges, ...[label]] }) }
    if (labelKind === 'demo-categories') { setState({ demoCategories: [...demoCategories, ...[label]] }) }
  }
  const removeLabel = (label, kind) => {
    if (kind === 'user-hardwares') { setState({ hardwares: hardwares.filter(item => item !== label) }) }
    if (kind === 'user-softwares') { setState({ softwares: softwares.filter(item => item !== label) }) }
    if (kind === 'user-interests') { setState({ interests: interests.filter(item => item !== label) }) }
    if (kind === 'user-skills') { setState({ skills: skills.filter(item => item !== label) }) }
    if (kind === 'user-languages') { setState({ languages: languages.filter(item => item !== label) }) }
    if (kind === 'user-accents') { setState({ accents: accents.filter(item => item !== label) }) }
    if (kind === 'user-gender-age') { setState({ genderAges: genderAges.filter(item => item !== label) }) }
    if (kind === 'demo-categories') { setState({ demoCategories: demoCategories.filter(item => item !== label) }) }
    if (kind === 'contains') { setState({ contains: contains.filter(item => item !== label) }) }
    if (kind === 'not-contains') { setState({ notContains: notContains.filter(item => item !== label) }) }
  }

  useDebouncedEffect(() => { fetchData({ newPage: true }) }, 200, [state])
  useDebouncedEffect(() => { fetchData({ newPage: true }) }, 300, [order, payment, classification])
  useDebouncedEffect(() => { fetchData({}) }, 200, [page])
  useEffect(() => {
    fetchData({ updateHistory: false })
  }, [])

  const loadMore = () => {
    if (!endOfResults) {
      setLoading(true)
      setPage(parseInt(page, 10) + 1)
    }
  }

  const fetchData = ({ newPage = false, talentKindOverride = false, updateHistory = true }) => {
    setDropdownOpen(false)
    let search = `is:${talentKind}`
    for (const item of hardwares) { search = `${search}|user-hardwares:${item}` }
    for (const item of softwares) { search = `${search}|user-softwares:${item}` }
    for (const item of interests) { search = `${search}|user-interests:${item}` }
    for (const item of skills) { search = `${search}|user-skills:${item}` }
    for (const item of languages) { search = `${search}|user-languages:${item}` }
    for (const item of accents) { search = `${search}|user-accents:${item}` }
    for (const item of genderAges) { search = `${search}|user-gender-age:${item}` }
    for (const item of demoCategories) { search = `${search}|demo-categories:${item}` }
    for (const item of contains) { search = `${search}|co:${item}` }
    for (const item of notContains) { search = `${search}|nc:${item}` }

    setLoading(true)
    const data = {
      page: newPage ? 1 : page,
      payment: payment,
      classification: classification,
      order_by: order,
      search: search
    }
    if (updateHistory) { history.push(`/?${createQueryString(data)}`) }
    getRequest('/api/v3/omnisearch/projects', data, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setLoading(false)
      setMeta(jsonData.meta)

      if (newPage) {
        setResultState({
          results: jsonData.results,
          favorites: jsonData.favoriteIds,
          dismissals: jsonData.dismissalIds
        })
        return
      }
      if (jsonData.results.length > 0) {
        setResultState({
          results: [...results, ...jsonData.results],
          dismissals: [...dismissals, ...jsonData.dismissalIds],
          favorites: [...favorites, ...jsonData.favoriteIds]
        })
      } else {
        setEndOfResults(true)
      }
    })
  }

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileEducationAd />
      <MobileAd adID={104} />
    </div>
    <div className='xl:block xl:flex xl:gap-x-2'>
    <div id='searchresults' className='max-w-6xl w-full'>
      <Card title={
        <div className='flex flex-col sm:flex-row items-center justify-between'>
          <span>
            Filters
          </span>
          <div className='flex gap-x-4'>
            <SavedSearchProjectModal />
            <button className='hover:underline dark:hover:underline text-cccblue dark:text-cccblue text-sm flex gap-x-1' onClick={clearFilters}>
            <SVGIcon name={'icon-dismiss'} className='h-4 w-4' />
            Clear Filters
            </button>
          </div>
        </div>}
      >
        <ul className="grid grid-cols-2 gap-1 sm:gap-2 sm:grid-cols-2 lg:grid-cols-6">
          { Object.keys(talentKindHash).map((key, idx) => (
            <li key={`role${key}`} className="col-span-1 flex shadow-sm rounded-md">
              { loading === key && <Loading noMessage noLoadingMessage /> }
              { loading !== key && <>
                <button onClick={() => setState({ talentKind: key })}
                  className={classNames(talentKind === key ? 'bg-cccblue bg-opacity-20 hover:bg-cccblue hover:bg-opacity-30 text-gray-900 dark:text-white' : 'dark:text-gray-200 dark:bg-gray-700 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-750 ', 'w-full flex space-x-1 items-center px-1 py-1 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple')}>
                  <SVGIcon name={talentKindHash[key].image} className='h-14 w-14' />
                  <span className='text-xs'> {talentKindHash[key].text}</span>
                  { talentKind === key && <CheckIcon className="h-3 w-3 text-cccblue" aria-hidden="true" /> }
              </button>
              </> }
            </li>
          ))}
        </ul>

        <div className='flex flex-col sm:flex-row justify-start gap-x-4 gap-y-2 sm:gap-y-0 sm:items-center'>
          <div className="inline-flex rounded-md" role="group">
            <button type="button" onClick={() => setPayment('all')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${payment === 'all' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              All
            </button>
            <button type="button" onClick={() => setPayment('paid')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${payment === 'paid' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              Paid
            </button>
            <button type="button" onClick={() => setPayment('deferred')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${payment === 'deferred' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              Deferred
            </button>
            <button type="button" onClick={() => setPayment('unpaid')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${payment === 'unpaid' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              Unpaid
            </button>
          </div>
          <div className="inline-flex rounded-md" role="group">
            <button type="button" onClick={() => setClassification('all')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${classification === 'all' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              All
            </button>
            <button type="button" onClick={() => setClassification('Original')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${classification === 'Original' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              Original
            </button>
            <button type="button" onClick={() => setClassification('Fan')} className={`py-1 px-2 text-sm font-medium text-gray-900 bg-white rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-cccblue ${classification === 'Fan' ? 'text-cccblue dark:text-cccblue' : ''}`}>
              Fan
            </button>
          </div>
          <button className='text-sm flex items-center dark:text-gray-100 dark:hover:text-cccblue hover:text-cccblue' onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>
            { showAdvancedFilters ? 'Hide' : 'Show' } Advanced Filters
            { totalAppliedFilters > 0 && <div className='text-sm'>(<b>{totalAppliedFilters}</b>)</div> }
            { showAdvancedFilters && <ChevronUpIcon className='h-4 w-4' /> }
            { !showAdvancedFilters && <ChevronDownIcon className='h-4 w-4' /> }
          </button>
        </div>

        { showAdvancedFilters && <>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="software" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Project or Role Name Contains </label>
              <div className="mt-1 sm:mt-0 max-w-xs flex">
                <input type='text' id='contains' className="" placeholder="Parody" aria-label="Search components" role="combobox" aria-expanded="true" onKeyDown={containKeyPress} />
                <button type="button" onClick={addContains} className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span>Add</span>
                </button>
              </div>
              { contains.length > 0 && <>
                <div className="mt-3 flex sm:items-center flex-wrap">
                  {contains.map((labeling, idx) => (
                    <span key={`contain${labeling}`} onClick={(id) => removeLabel(labeling, 'contains')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                  ))}
                </div>
              </> }
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="software" className="text-sm font-medium text-gray-700 dark:text-gray-200"> Project or Role Name does NOT contain </label>
              <div className="mt-1 sm:mt-0 max-w-xs flex">
                <input type='text' id='not_contains' className="" placeholder="Gacha" aria-label="Search components" role="combobox" aria-expanded="true" onKeyDown={notContainKeyPress} />
                <button type="button" onClick={addNotContains} className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                  <span>Add</span>
                </button>
              </div>
              { notContains.length > 0 && <>
                <div className="mt-3 flex sm:items-center flex-wrap">
                  {notContains.map((labeling, idx) => (
                    <span key={`contain${labeling}`} onClick={(id) => removeLabel(labeling, 'not-contains')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                  ))}
                </div>
              </> }
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="languages" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><GlobeAltIcon className='h-4 w-4' /> Language Spoken  </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-languages' addLabel={addLabel} placeholder='English, Japanese'/>
                { languages.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {languages.map((labeling, idx) => (
                      <span key={`lang${labeling}`} onClick={(id) => removeLabel(labeling, 'user-languages')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="voice_age_and_gender" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><MicrophoneIcon className='h-4 w-4' /> Voice Age & Gender  </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-gender-age' addLabel={addLabel} placeholder='Female Young Adult, Male Teen, Baby'/>
                { genderAges.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {genderAges.map((labeling, idx) => (
                      <span key={`lang${labeling}`} onClick={(id) => removeLabel(labeling, 'user-gender-age')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="voice_description" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><MicrophoneIcon className='h-4 w-4' /> Voice Description  </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='demo-categories' addLabel={addLabel} placeholder='Character, Warm, Funny, Dark' />
                { demoCategories.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {demoCategories.map((labeling, idx) => (
                      <span key={`democa${labeling}`} onClick={(id) => removeLabel(labeling, 'demo-categories')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="accents" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><MicrophoneIcon className='h-4 w-4' /> Accents  </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-accents' addLabel={addLabel} placeholder='Texan, Irish, French'/>
                { accents.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {accents.map((labeling, idx) => (
                      <span key={`acce${labeling}`} onClick={(id) => removeLabel(labeling, 'user-accents')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="software" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><CogIcon className='h-4 w-4' /> Software </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-softwares' addLabel={addLabel} placeholder='ISDN, Audacity, Source Connect, Scrivener'/>
                { softwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {softwares.map((labeling, idx) => (
                      <span key={`soft${labeling}`} onClick={(id) => removeLabel(labeling, 'user-softwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="hardware" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><CogIcon className='h-4 w-4' /> Hardware </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-hardwares' addLabel={addLabel} placeholder='Scarlett FocusRite 2i2, Shure SM7b, Blue Yeti'/>
                { hardwares.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {hardwares.map((labeling, idx) => (
                      <span key={`hard${labeling}`} onClick={(id) => removeLabel(labeling, 'user-hardwares')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="skills" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><CogIcon className='h-4 w-4' /> Skills </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-skills' addLabel={addLabel} placeholder='Kung Fu Fighting, Foreboding Glare, Mediocre DadJokes'/>
                { skills.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {skills.map((labeling, idx) => (
                      <span key={`sskill${labeling}`} onClick={(id) => removeLabel(labeling, 'user-skills')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

            <div className='rounded-sm p-2 bg-cccblue bg-opacity-5'>
              <label htmlFor="interests" className="text-sm font-medium text-gray-700 dark:text-gray-200 flex space-x-2 items-center"><CogIcon className='h-4 w-4' /> Interests </label>
              <div className="mt-1 sm:mt-0 max-w-xs">
                <TagAutocomplete kind='user-interests' addLabel={addLabel} placeholder='Manga, Music, Audiobooks'/>
                { interests.length > 0 && <>
                  <div className="mt-3 flex sm:items-center flex-wrap">
                    {interests.map((labeling, idx) => (
                      <span key={`inte${labeling}`} onClick={(id) => removeLabel(labeling, 'user-interests')} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-cccorange dark:bg-cccpurple text-xs font-medium rounded-full flex cursor-pointer capitalize">{labeling} <XIcon className='w-4 h-4' /></span>
                    ))}
                  </div>
                </> }
              </div>
            </div>

          </div>
        </> }
      </Card>

      <div className='flex justify-between items-center py-2'>
        <div className='dark:text-gray-100'>Showing <b>{meta?.totalEntries}</b> open roles</div>
        <div className='flex space-x-2 items-center'>
          <div className='relative flex items-center'>
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-800 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-48'>
              <span className="sr-only" hidden>Open options</span>
              <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              <span className='truncate'>Sort by <b>{orderMap[order]}</b></span>
            </button>
            { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
              <div onClick={() => setOrder('magic')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.magic}</div>
              <div onClick={() => setOrder('popular')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.popular}</div>
              <div onClick={() => setOrder('deadline')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.deadline}</div>
              <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.updated_at}</div>
              <div onClick={() => setOrder('listed_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.listed_at}</div>
            </div> }
          </div>
        </div>
      </div>
      <section className="flex flex-col mt-2 space-y-2">
        { results.map((result, idx) => {
          return <ProjectResultRow key={`${idx}presult${result.projectId}`} result={result} lastElementInList={idx + 1 === results.length} loadMore={loadMore} favorites={favorites} dismissals={dismissals} />
        })}
        { endOfResults && <EndOfResults clearFilters={clearFilters} /> }
        { loading && <Loading /> }
      </section>
    </div>
    { !hideAdSidebar && <div className='flex flex-col gap-y-1'>
      <SidebarEducationAd />
      <SidebarAd adID={105} />
    </div> }
  </div>
  </>
}

const EndOfResults = ({ clearFilters }) => {
  return (
    <span>
      <button onClick={clearFilters} type="button" className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <StarIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> That's all, folks! </span>
        <div className="mt-2 block text-sm font-medium text-cccblue cursor-pointer"> No more results found. Click here to Clear Filters and search some more. </div>
      </button>
    </span>
  )
}

const LoadMoreDiv = ({ callback, endOfResults }) => {
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  useEffect(() => { if (isVisible && !endOfResults) { callback() } }, [isVisible])
  return <div className='text-center cursor-pointer text-cccblue p-4 text-lg' ref={ref} onClick={callback}>Load More</div>
}

const ProjectResultRow = ({ result, lastElementInList, loadMore, favorites, dismissals, endOfResults }) => {
  const { project, roles } = result
  const { putpostRequest } = useQuery()
  const [favorited, setFavorited] = useState(favorites.includes(project.projectId))
  const [dismissed, setDismissed] = useState(dismissals.includes(project.projectId))

  const handleFavorite = () => {
    setFavorited(!favorited)
    putpostRequest(`/api/v3/favorites/${project.projectId}/favorite_project`, 'POST', {}, (err, jsonData) => {
      if (err) { return console.log(err) }
      // noop
    })
  }

  const handleDismis = () => {
    setDismissed(!dismissed)
    putpostRequest('/api/v3/dismissals/toggle', 'POST', { project_id: project.projectId }, (err, jsonData) => {
      if (err) { return console.log(err) }
      // noop
    })
  }

  if (dismissed) {
    return <>
      <div className='p-2 bg-gray-700 dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded flex justify-between items-center'>
        <a target="_blank" className="text-xs" href={`/projects/${project.slug}`} rel="noreferrer">{project.name}</a>
        <div onClick={handleDismis} className='cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 p-1 tooltip text-gray-500'>
          <span className="tooltiptext">Reconsider</span>
          <SVGIcon name='icon-dismiss' className='h-4 w-4' />
        </div>
      </div>
      { lastElementInList && <LoadMoreDiv callback={loadMore} endOfResults={endOfResults} /> }
    </>
  }

  return <>
  <div className={`p-2 bg-${project.bgColor} dark:bg-gray-800 bg-opacity-10 dark:bg-opacity-100 rounded border-${project.bgColor} border-l-4`}>
    <div className={'grid grid-cols-3'}>
      <div className={'col-span-3 sm:col-span-2 flex items-start gap-x-3'}>
        <div className="relative m-1 hidden sm:block">

          <div className="w-52 aspect-w-16 aspect-h-9">
            <img className={`shadow-lg rounded-lg ring-4 ring-${project.bgColor}`}  src={project.imageUrl} alt={project.name} />
          </div>

          <span className={`absolute -bottom-1 -right-1 bg-${project.bgColor} rounded-tl px-0.5 py-px`}>
            <FilmIcon className="h-4 w-4 text-white" aria-hidden="true" />
          </span>
        </div>
        <div className='flex flex-col col-span-3 sm:col-span-2'>
          <div className='flex justify-between'>
            <div>{project.featured && <div><span className='px-2 text-xs rounded-md text-white bg-gradient-to-r from-cccpurple via-cccblue to-cccorange'>Featured</span></div>}</div>
            <div className={'sm:hidden flex gap-x-2 items-start justify-end'}>
              <div onClick={handleFavorite} className='cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 p-2 tooltip'>
                <span className="tooltiptext">Save for later</span>
                {favorited && <SVGIcon name='icon-favorited' className='h-4 w-4' /> }
                {!favorited && <SVGIcon name='icon-favorites' className='h-4 w-4' /> }
              </div>
              <div onClick={handleDismis} className='cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 p-2 tooltip'>
                <span className="tooltiptext">Dismiss</span>
                <SVGIcon name='icon-dismiss' className='h-4 w-4' />
              </div>
            </div>
          </div>
          <a target="_blank" className="text-xl" href={`/projects/${project.slug}`} rel="noreferrer">{project.name}</a>
          <div className='flex gap-x-1 items-center text-xs text-gray-400 dark:text-gray-500'>
            <span>listed by</span> <a className="text-sm" href={`/${project.username}`}>{project.displayName}</a>
          </div>
          <div className='flex gap-x-3'>
            <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={project.listedAt}>Listed: {project.listedAtInWords}</time>
            <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={project.deadline}>Deadline: {project.deadlineInWords}</time>
            <div className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center'>{project.classification}</div>
            <div className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center'>{project.category}</div>
          </div>
          <div className='flex flex-row justify-start items-center gap-x-3 my-1 text-gray-900 font-medium text-sm text-gray-700 dark:text-gray-200'>
            <span className='tooltip'>
              <span className='tooltiptext'>Open Roles</span>
              <div className='flex items-center space-x-1'>
                <SVGIcon name='icon-manage-projects' className='h-6 w-6' />
                <span><span className='tooltip'>{project.rolesCount}</span></span>
              </div>
            </span>
            <span className='tooltip'>
              <span className='tooltiptext'>Number of submissions</span>
              <div className='flex items-center space-x-1'>
                <SVGIcon name='icon-submissions' className='h-6 w-6' />
                <span><span className='tooltip'>{project.auditionsCount}</span></span>
              </div>
            </span>
            <span className='tooltip'>
              <span className='tooltiptext'>Number of comments</span>
              <div className='flex items-center space-x-1'>
                <SVGIcon name='icon-messages' className='h-6 w-6' />
                <span><span className='tooltip'>{project.commentsCount}</span></span>
              </div>
            </span>
            <span className='tooltip'>
              <span className='tooltiptext'>Number of people following this project</span>
              <div className='flex items-center space-x-1'>
                <SVGIcon name='icon-followers' className='h-6 w-6' />
                <span><span className='tooltip'>{project.followeesCount}</span></span>
              </div>
            </span>
          </div>
          <a target="_blank" href={`/projects/${project.slug}`} className='sm:hidden px-4 py-2 text-center border border-gray-300 rounded-md shadow-sm text-xs text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple' rel="noreferrer">View Project Details</a>
        </div>
      </div>
      <div className={'hidden sm:block col-span-1 flex flex-col justify-start items-end space-y-2'}>
        <div className={'flex gap-x-5 items-start justify-end'}>
          <div onClick={handleFavorite} className='cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 p-2 tooltip'>
            <span className="tooltiptext">Save for later</span>
            {favorited && <SVGIcon name='icon-favorited' className='h-5 w-5' /> }
            {!favorited && <SVGIcon name='icon-favorites' className='h-5 w-5' /> }
          </div>
          <div onClick={handleDismis} className='cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 p-2 tooltip'>
            <span className="tooltiptext">Dismiss</span>
            <SVGIcon name='icon-dismiss' className='h-5 w-5' />
          </div>
        </div>
        <div className='flex items-start justify-end'>
          <a target="_blank" href={`/projects/${project.slug}`} className='hidden sm:block px-4 py-2 border border-gray-300 rounded-md shadow-sm sm:text-sm font-medium text-gray-700 bg-white dark:bg-gray-900 dark:text-white dark:hover:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccpurple' rel="noreferrer">View Project Details</a></div>
      </div>
    </div>

    <div className='mt-1 flex flex-col text-sm text-gray-800 dark:text-gray-200'>
      <div className='prose-sm' dangerouslySetInnerHTML={{ __html: project.description }} />
    </div>
    <ProjectRolesIndex roles={roles} project={project} />
  </div>
  { lastElementInList && <LoadMoreDiv callback={loadMore} /> }
  </>
}

const ProjectRolesIndex = ({ roles, project }) => {
  const [showAll, setShowAll] = useState(false)
  const shouldShowLoadMoreButton = roles.length > 3
  return <>
    { roles.map((role, idx) => {
      if (!showAll && idx > 2) return null

      return <RoleSearchRow key={`${idx}rresult${role.id}`} role={role} project={project} />
    })}
    { !showAll && shouldShowLoadMoreButton && <div onClick={() => setShowAll(true)} className='cursor-pointer text-cccblue'>Load {roles.length - 3} more</div> }
  </>
}

export default SearchIndex

SearchIndex.propTypes = { }
