import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import UserHoverCard from '@/shared/UserHoverCard'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'

import Pagination from '@/shared/Pagination'
import Tabs from '@/shared/Tabs'
import Loading from '@/shared/Loading'
import { AnnotationIcon, EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon, SortAscendingIcon } from '@heroicons/react/solid'
import Card from '@/shared/Card'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <AnnotationIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No recommendations. Yet. I believe in you.</span>
      </div>
    </span>
  )
}

const RecommendationsRow = ({ rec, received }) => {
  const { putpostRequest } = useQuery()
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const [, setLoading] = useState(false)
  const [status, setStatus] = useState(rec.status)
  const [deleted, setDeleted] = useState(false)

  const handleContentClick = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) { return }

    const to = e.target.getAttribute('data-to')
    const isMention = e.target.getAttribute('data-mention')

    if (isMention === 'mention') {
      e.preventDefault()
      history.push('/' + to)
    }
  }

  const changeStatus = (perm) => {
    setStatus(perm)
    setDropdownOpen(false)
    if (perm === 'delete') { return }

    const data = { status: perm }
    putpostRequest(`/api/v3/recommendations/${rec.id}`, 'PATCH', data, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  const remove = () => {
    const data = { status: 'deleted' }
    putpostRequest(`/api/v3/recommendations/${rec.id}`, 'PATCH', data, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          console.log(key, value)
        })
        return
      }
      setDeleted(true)
    })
  }

  if (deleted) { return null }

  let user
  if (received) {
    user = rec.giverUser
  } else {
    user = rec.receiverUser
  }

  return <li className='bg-white dark:bg-gray-700 sm:p-1 rounded-lg border border-gray-200 dark:border-gray-700'>
    <div className="flex items-start space-x-0 sm:space-x-3">
      <div className="relative m-1 hidden sm:block">
        <img className="h-16 w-16 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-cccblue" src={user.imageUrl} alt="" />

        <span className="absolute -bottom-0.5 -right-1 bg-cccblue rounded-tl px-0.5 py-px">
          <AnnotationIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="flex flex-col justify-start flex-1">
        <div><UserHoverCard username={user.username} displayName={user.displayName} /></div>
        <div className="mt-1 text-sm text-gray-600 dark:text-gray-400 flex flex-col sm:flex-row">
          <span className="uppercase text-xs dark:text-gray-300 text-gray-500 mr-5">
            <time dateTime={rec.createdAt}>{rec.createdAt}</time>
          </span>
        </div>
        <div className="mb-4">
          <p onClick={handleContentClick} className="text-sm dark:text-gray-300 text-gray-800" dangerouslySetInnerHTML={{ __html: rec.body }}></p>
        </div>
      </div>
      <div className='relative flex items-center'>
        { status !== 'delete' && <div onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-0 inline-flex items-center space-x-1 rounded-md cursor-pointer text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300">
          { status === 'pending' && <FingerPrintIcon className="h-5 w-5" aria-hidden="true" /> }
          { status === 'approved' && <EyeIcon className="h-5 w-5" aria-hidden="true" /> }
          <span className='capitalize hidden sm:block text-xs'>{status}</span>
        </div> }
        { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`origin-top-left sm:origin-top-right absolute z-10 right-0 mt-2 w-64 rounded-md bg-white dark:bg-gray-800 divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none  ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition border border-gray-900 dark:border-gray-100 rounded-md top-6 max-h-80`}>
          { received && <>
          <div className="flex flex-col  cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('approved')}>
            <EyeIcon className="h-5 w-5" aria-hidden="true" />
            <div className="flex justify-between">
              <p className='font-normal capitalize'>Approve</p>
              { status === 'approved' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
            </div>
            <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This recommendation will appear on your profile.</span>
          </div>
          <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('pending')}>
            <FingerPrintIcon className="h-5 w-5" aria-hidden="true" />
            <div className="flex justify-between">
              <p className='font-normal capitalize'>Pending</p>
              { status === 'pending' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
            </div>
            <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This recommendation will not appear on your profile until you approve it. You can also delete it.</span>
          </div>
          </> }
          <div className="flex flex-col cursor-default select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changeStatus('delete')}>
            <EyeOffIcon className="h-5 w-5" aria-hidden="true" />
            <div className="flex justify-between">
              <p className='font-normal capitalize'>Delete</p>
              { status === 'delete' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
            </div>
            <span className='text-xs sm:text-sm text-gray-500 dark:text-gray-200 dark:bg-gray-800'>This recommendation will be deleted if you choose this option.</span>
          </div>
        </div> }
        { status === 'delete' && <div className='flex flex-col items-start text-xs'>
          <span className='text-sm mb-1'>Really delete it?</span>
          <div className='cursor-pointer text-red-500' onClick={remove}>Yes! Do it, computer!</div>
          <div className='cursor-pointer text-yellow-500' onClick={() => changeStatus('pending')}>On second thought. Keep it.</div>
        </div> }
      </div>
    </div>
  </li>
}

const RecommendationsIndex = () => {
  const [meta, setMeta] = useState({})
  const [recs, setRecs] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('created_at desc')
  const [status, setStatus] = useState('received')
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const statuses = ['received', 'given']

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [status, order])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    const data = {
      status: status,
      order: order,
      page: newPage ? 1 : page
    }
    getRequest('/api/v3/recommendations', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setRecs(jsonData.recommendations)
      setMeta(jsonData.meta)
    })
  }

  const changeTab = (tab) => { setStatus(tab) }
  const tablist = [
    { name: 'Received', count: null, changeValue: 'received' },
    { name: 'Given', count: null, changeValue: 'given' }
  ]
  const received = status === 'received'

  return <>
    <Card light title='Recommendations'>
      <div className='h-75vh overflow-y-auto'>
        <div className='pb-1' ref={scrollRef}>
          <div className='flex justify-between items-center'>
            <Tabs statuses={statuses} tablist={tablist} currentTab={status} changeTab={changeTab} />
            <div className='flex space-x-2'>
              <div className='relative flex items-center'>
                <button onClick={() => setDropdownOpen(!dropdownOpen)} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center'>
                  <span className="sr-only" hidden>Open options</span>
                  <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                  {order === 'created_at desc' ? 'Recent First' : 'Oldest First'}
                </button>
                { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                  <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Oldest First</div>
                  <div onClick={() => setOrder('created_at desc')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >Recent First</div>
                </div> }
              </div>
            </div>
          </div>
          { recs.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        { loading && <Loading /> }
        <ul role="list" className='space-y-2'>
          {recs.map((rec) => (
            <RecommendationsRow key={`recrow${rec.id}`} rec={rec} received={received} />
          ))}
        </ul>
        { recs.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        { recs.length === 0 && !loading && <NoResults /> }
      </div>
    </Card>
  </>
}

export default RecommendationsIndex

RecommendationsRow.propTypes = {
  rec: PropTypes.object.isRequired,
  received: PropTypes.string
}
