import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Loading from '@/shared/Loading'
import { SidebarAd, MobileAd, SidebarEducationAd, MobileEducationAd } from '@/Layout/Ads'
import { useGlobalState } from '@/state'
import DefaultButton from '@/shared/Buttons/Default'
import useQuery from '@/hooks/useQuery'
import Card from '@/shared/Card'
import Tabs from '@/shared/Tabs'

const AchievementsIndex = () => {
  const { getRequest, putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState('all')
  const [achievements, setAchievements] = useState([])
  const [selected, setSelected] = useState([])
  const scrollRef = useRef(null)

  useEffect(() => { fetchData() }, [])

  const addSelected = (name) => {
    putpostRequest('/api/v3/achievements/select', 'POST', { achievement: { name: name } }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setSelected(jsonData.achievements.filter(x => x.flair))
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Nice flair, yo</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Everyone will see it. It'll be great!</p>
      </div>)
    })
  }

  const removeSelected = (name) => {
    putpostRequest('/api/v3/achievements/deselect', 'POST', { achievement: { name: name } }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setSelected(jsonData.achievements.filter(x => x.flair))
    })
  }

  const fetchData = (newPage) => {
    setLoading(true)
    const data = { }
    getRequest('/api/v3/achievements', data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setAchievements(jsonData.default_achievements)
      setSelected(jsonData.achievements.filter(x => x.flair))
    })
  }

  const changeTab = (newtab) => { setTab(newtab) }

  const tablist = [
    { name: 'All Achievements', count: null, changeValue: 'all' },
    { name: 'Earned', count: null, changeValue: 'earned' },
    { name: 'Not Earned', count: null, changeValue: 'unearned' }
  ]

  return <>
    <div className='flex flex-col gap-y-1'>
      <MobileEducationAd />
      <MobileAd adID={109} />
    </div>
    <div className='lg:block lg:flex lg:gap-x-2'>
      <div id='achievements' className='max-w-6xl w-full'>
        <Card title='Selected Flair'>
          <div className="gap-y-3 grid grid-cols-1 sm:grid-cols-3 gap-x-1">
            {selected.map((ach) => <span key={`selectedach${ach.name}`}>
              <AchievementRow achievement={ach} addSelected={addSelected} removeSelected={removeSelected} />
            </span>)}
          </div>
        </Card>
        <div className='mt-2' />
        <Card title='Achievements'>
          <>
            <div ref={scrollRef}>
              <div className='flex justify-between items-center'>
                <Tabs statuses={{}} tablist={tablist} currentTab={'all'} changeTab={changeTab} />
              </div>
            </div>
            { loading && <Loading /> }
            <div className="gap-y-3 grid grid-cols-1 sm:grid-cols-3 gap-x-1">
              {achievements.map((ach) => {
                if (tab === 'all') return <span key={`ach${ach.name}`}> <AchievementRow achievement={ach} addSelected={addSelected} removeSelected={removeSelected} /></span>

                if (tab !== 'all' && (tab === 'earned' && ach.earned)) {
                  return <span key={`ach${ach.name}`}> <AchievementRow achievement={ach} addSelected={addSelected} removeSelected={removeSelected} /></span>
                } else if (tab !== 'all' && (tab === 'unearned' && !ach.earned)) {
                  return <span key={`ach${ach.name}`}> <AchievementRow achievement={ach} addSelected={addSelected} removeSelected={removeSelected} /></span>
                } else { return null }
                })}
            </div>
          </>
        </Card>
      </div>
      <div className='flex flex-col gap-y-1'>
        <SidebarEducationAd />
        <SidebarAd adID={108} />
      </div>
    </div>
  </>
}

const AchievementRow = (props) => {
  const { achievement, addSelected, removeSelected } = props
  return <div className={`px-3 py-2 col-span-1 relative border border-1 border-black dark:border-cccpurple rounded-lg bg-white dark:bg-gray-900 dark:text-white ${achievement.earned ? 'bg-cccblue dark:bg-cccblue bg-opacity-50' : ''}` }>
    <div>
      <img className="w-16 absolute top-0 right-0" src={achievement.url} />
      <div className="uppercase text-sm text-cccorange">{achievement.base ? '[BASE] ' : ''}{achievement.title}</div>
      <div className="text-sm font-semibold">{achievement.earned ? 'Unlocked!' : 'Locked'} | Earns {achievement.coins} cccCoins</div>
    </div>
    <div className="text-sm flex flex-col">
      <div>{achievement.desc}</div>
      { achievement.earned && !achievement.flair && <DefaultButton onClick={() => addSelected(achievement.name)} text={'Set as Flair'} /> }
      { achievement.flair && <DefaultButton onClick={() => removeSelected(achievement.name)} text={'Remove'} /> }
    </div>
  </div>
}

AchievementRow.propTypes = {
  achievement: PropTypes.object.isRequired,
  removeSelected: PropTypes.func.isRequired,
  addSelected: PropTypes.func.isRequired
}

export default AchievementsIndex
