import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useQuery from '@/hooks/useQuery'

import { ShareIcon, DuplicateIcon } from '@heroicons/react/solid'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useGlobalState } from '@/state'
import useModal from '@/hooks/useModalV2'
import Loading from '@/shared/Loading'
import Modal from '@/shared/Modal'

const SubmissionShare = ({ submission }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [videoUrl, setVideoUrl] = useState(submission.videoUrl)
  const { putpostRequest } = useQuery()
  const [, setToast] = useGlobalState('toast')

  const maybeCreateVideo = () => {
    putpostRequest(`/api/v3/auditions/${submission.id}/share_video`, 'POST', {}, (err, jsonData) => {
      if (err) { /* hook */ return }

      setVideoUrl(jsonData.videoUrl)
    })
    openModal()
  }

  const reload = () => { window.location.reload() }

  const showCopiedNotification = () => {
    setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium dark:text-green-300 text-green-600">Copied</p>
      <p className="mt-1 text-sm dark:text-green-300 text-green-400">You did it! It's copied. You can paste it anywhere you want now.</p>
    </div>)
  }
  return <>
    <button type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center" onClick={maybeCreateVideo}>
      <ShareIcon className="h-5 w-5" aria-hidden="true" />
      <span className="text-xs hidden sm:block">Share</span>
    </button>
    <Modal isOpen={isOpen} full={true} closeModal={closeModal} >
      <div className="flex flex-col w-full text-center justify-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Share this submission! </h2>
        <CopyToClipboard text={`https://cstng.cc/s/${submission.id}`} onCopy={showCopiedNotification} >
          <span className='flex flex-col sm:flex-row justify-center items-center cursor-pointer p-2'>
            <DuplicateIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" /> <span>Copy Link </span>
            <pre className='bg-gray-50 dark:bg-gray-800 ml-2'>{`https://cstng.cc/s/${submission.id}`}</pre>
          </span>
        </CopyToClipboard>
        <div className='flex flex-center p-5 space-x-2 justify-center items-center'>
          <a rel="noreferrer" target="_blank" href={`https://twitter.com/intent/tweet?url=https://cstng.cc/s/${submission.id}&text=${submission.projectName} submission via @CastingCallClub`}>
            <span className="sr-only" hidden>Twitter</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>

          <a rel="noreferrer" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://cstng.cc/s/${submission.id}&text=${submission.projectName} submission via @CastingCallClub`}>
            <span className="sr-only" hidden>Facebook</span>
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
        { !videoUrl && <>
          <Loading />
          <div className='p-4 rounded'>If you give CCC a sec, we will create a video file of your submission. It takes about 2 minutes. Go have a cup of anything but milk, and <a className='cursor-pointer' onClick={reload}>refresh the page</a>.</div>
        </> }
        { videoUrl && <video controls>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support video
        </video> }
      </div>
    </Modal>
  </>
}
SubmissionShare.propTypes = {
  submission: PropTypes.object
}

export default SubmissionShare
