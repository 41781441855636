import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'

import { useGlobalState } from '@/state'
import useDropdown from '@/hooks/useDropdown'
import { MenuIcon } from '@heroicons/react/outline'

import classNames from '@/utils/classNamesLocal'

const styles = {
  desktop: {
    selectedTab: 'border-cccblue text-cccblue dark:text-white dark:bg-cccblue',
    unselectedTab: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-white',
    defaultTab: 'whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm rounded-t-md'
  },
  mobile: {
    selectedTab: 'border-cccblue text-cccblue',
    unselectedTab: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-white',
    defaultTab: 'block rounded-md pt-2 px-3 text-base font-medium'
  }
}

const TabCount = ({ number, selected }) => {
  if (typeof (number) === 'undefined') return null
  if (parseInt(number, 10) <= 0) return null

  return <>
    <span className={classNames(
      selected ? 'bg-cccblue text-white dark:bg-white dark:text-cccblue' : 'bg-gray-200 text-gray-900',
      'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}

const Tab = ({ url, name, view, closeDropdown, count }) => {
  const location = useLocation()
  const history = useHistory()
  const activePath = location.pathname
  const selected = activePath === url

  const handleOnClick = () => {
    closeDropdown()
    history.push(url)
  }

  return (
    <button onClick={handleOnClick} className={classNames(
        selected ? styles[view].selectedTab : styles[view].unselectedTab, styles[view].defaultTab
      )} aria-current={selected ? 'page' : undefined} aria-label={name}>
      {name}
      <TabCount number={count} selected={selected} />
    </button>
  )
}

export default function ManageProjectsTabs() {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { tabs } = manageProjectContainer

  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)
  const handleClick = () => { setDropdownOpen(false) }

  return <>
    <div className="sm:divide-y sm:divide-gray-200 mt-2 sm:mt-0">
      <div className="sm:border-b border-gray-200">
        <nav className="hidden lg:py-2 lg:flex lg:space-x-5" aria-label="Global">
          <Tab closeDropdown={handleClick} name='Project Dashboard' url={`/manage/projects/${tabs.projectId}`} view='desktop' />
          <Tab closeDropdown={handleClick} name='Edit Project' url={`/manage/projects/${tabs.projectId}/edit`} view='desktop' />
          <Tab closeDropdown={handleClick} name='Manage Roles' count={tabs.rolesCount} url={`/manage/projects/${tabs.projectId}/roles`} view='desktop' />
          <Tab closeDropdown={handleClick} name='Submissions' count={tabs.submissionsCount} url={`/manage/projects/${tabs.projectId}/submissions`} view='desktop' />
          <Tab closeDropdown={handleClick} name='Project DMs' count={tabs.messageCount} url={`/manage/projects/${tabs.projectId}/messages`} view='desktop' />
        </nav>
      </div>
      <div className="relative z-10 flex items-center lg:hidden">
        <button onClick={() => setDropdownOpen(!dropdownOpen)} className="py-1 px-2 sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          <span className="sr-only" hidden>Open filter menu</span>
          <MenuIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
    { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute left-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
      <div className="pt-2 pb-3 px-2 space-y-1">
          <Tab closeDropdown={handleClick} name='Project Dashboard' url={`/manage/projects/${tabs.projectId}`} view='mobile' />
          <Tab closeDropdown={handleClick} name='Edit Project' url={`/manage/projects/${tabs.projectId}/edit`} view='mobile' />
          <Tab closeDropdown={handleClick} name='Manage Roles' url={`/manage/projects/${tabs.projectId}/roles`} view='mobile' />
          <Tab closeDropdown={handleClick} name='Submissions' count={tabs.submissionsCount} url={`/manage/projects/${tabs.projectId}/submissions`} view='mobile' />
          <Tab closeDropdown={handleClick} name='Project DMs' count={tabs.messageCount} url={`/manage/projects/${tabs.projectId}/messages`} view='mobile' />
      </div>
    </div> }
  </>
}

Tab.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  closeDropdown: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  count: PropTypes.number
}
TabCount.propTypes = {
  number: PropTypes.number,
  selected: PropTypes.bool
}
ManageProjectsTabs.propTypes = {}
