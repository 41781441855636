import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'throttle-debounce'
import { useGlobalState } from '@/state'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import { useForm } from 'react-hook-form'
import TipTapWysiwyg from '@/shared/TipTapWysiwyg'
import DefaultButton from '@/shared/Buttons/Default'
import PrimaryButton from '@/shared/Buttons/Primary'
import Modal from '@/shared/Modal'
import useModal from '@/hooks/useModalV2'

const ProjectUpdateEdit = ({ initialUpdate, deletePu }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const tipTapIdx = useRef(1)
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest, getRequest } = useQuery()
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  const [ pu, setPu ] = useState(initialUpdate)
  const { formState, register, reset, watch, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
     title: initialUpdate.title,
     body: initialUpdate.body
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  const destroy = () => {
    putpostRequest(`/api/v3/project_updates/${pu.id}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ return }

      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-500">Deleted.</p>
        <p className="mt-1 text-sm text-green-500">I can't believe it's already gone.  I mean, computers are quite fast, but wow, that was blazing.</p>
      </div>)
      deletePu()
      closeModal()
    })
  }

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const formData = {
      project_update: {
        body: data.body,
        title: data.title
      }
    }

    putpostRequest(`/api/v3/project_updates/${initialUpdate.id}`, 'PATCH', formData, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err === 'string') { // 422 custom error
          setCustomError(err)
        }
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Saved</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">I remember the first time I saved an update. Amazing feeling.</p>
      </div>)
      reset({ ...getValues() })
      setPu(jsonData.projectUpdate)
      closeModal()
    })
  })

  const body = watch('body')

  return <>
    <DefaultButton onClick={openModal} text='Edit' />
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <div className="flex flex-col text-center items-center">
        <h2 className="mb-8 text-xs font-semibold tracking-widest text-gray-800 dark:text-gray-200 uppercase title-font">Edit Update</h2>
      </div>
      <div className="col-span-4 sm:col-span-2">
        <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Subject of Update </label>
        <div className="mt-1 flex flex-col rounded-md shadow-sm">
          <div className='relative'>
            <input type="text" className={errors.title ? 'errors' : ''} {...register('title', { required: true }) } placeholder="We've extended the deadline" />
            { errors.title && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div> }
          </div>
        </div>
        { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
      </div>

      <div className='col-span-2 mt-2'>
        <label htmlFor="body" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
          What would you like to tell everyone?
        </label>
        <TipTapWysiwyg
          html={body}
          idx={tipTapIdx.current}
          updateHtml={(val) => handleUpdateTipTap('body', val)}
          placeholder='Prepare yourself for the most important update of the century: there are cookies in the lobby.'
        />
      </div>
      <div className="rounded-md bg-blue-50 mt-4 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">Project Updates are emailed once a day, but they are available immediately on your project page</p>
          </div>
        </div>
      </div>
      { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }
      <div className="flex justify-between space-x-2 mt-4">
        { confirmDelete && <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600' onClick={destroy}>You put so much effort into this. Really delete?</button> }
        { !confirmDelete && <button className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600' onClick={() => setConfirmDelete(true)}>Delete</button> }
        <div className="flex justify-end space-x-2">
          <DefaultButton onClick={closeModal} text='Cancel' />
          <PrimaryButton onClick={handleSubmit(onSubmit)} text='Save' />
        </div>
      </div>
    </Modal>
  </>
}

export default ProjectUpdateEdit
