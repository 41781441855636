import React, { useState, useEffect, useRef } from 'react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import Pagination from '@/shared/Pagination'
import Loading from '@/shared/Loading'
import { useGlobalState } from '@/state'
import { EyeIcon, SortAscendingIcon } from '@heroicons/react/solid'
import useQuery from '@/hooks/useQuery'
import useDropdown from '@/hooks/useDropdown'
import Card from '@/shared/Card'
import SubmissionRow from '@/pages/ManageProjects/SubmissionRow'
import Tabs from '@/shared/Tabs'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> This person hasn't listed any submissions as visible on their profile.</span>
      </div>
    </span>
  )
}

const SubmissionsIndex = () => {
  const [manageProjectContainer] = useGlobalState('manageProjectContainer')
  const { project } = manageProjectContainer
  const [currentUser] = useGlobalState('currentUser')
  const user = currentUser
  const [meta, setMeta] = useState({})
  const [submissions, setSubmissions] = useState([])
  const { getRequest } = useQuery()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [reviewStatuses, setReviewStatuses] = useState([])
  const [order, setOrder] = useState('updated_at')
  const [role, setRole] = useState('all')
  const [roles, setRoles] = useState([])
  const [reviewStatus, setReviewStatus] = useState('unsorted')
  const [searchOpen, setSearchOpen] = useState(false)
  const scrollRef = useRef(null)
  const dropdownRef = useRef(false)
  const { dropdownOpen, setDropdownOpen } = useDropdown(dropdownRef)

  useDebouncedEffect(() => {
    setDropdownOpen(false)
    fetchData(true)
  }, 200, [contains, order, reviewStatus, role])

  useEffect(() => {
    getRequest(`/api/v3/manage/projects/${project.id}/roles`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setRoles(jsonData.roles)
    })
  }, [])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    setLoading(true)
    let data = {
      contains: contains,
      order_by: order,
      review_status: reviewStatus,
      page: newPage ? 1 : page
    }
    if (role !== 'all') { data['role_id'] = role }
    getRequest(`/api/v3/manage/projects/${project.id}/submissions`, data, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setReviewStatuses(jsonData.reviewStatuses)
      setSubmissions(jsonData.submissions)
      setMeta(jsonData.meta)
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') {
      setOrder(e.target.value)
    }
    if (e.target.name === 'contains') {
      setContains(e.target.value)
    }
  }

  const changeTab = (tab) => { setReviewStatus(tab) }

  const tablist = [
    { name: 'Unsorted', count: reviewStatuses?.unsorted, changeValue: 'unsorted' },
    { name: 'Assigned', count: reviewStatuses?.assigned, changeValue: 'assigned' },
    { name: 'Shortlisted', count: reviewStatuses?.shortlisted, changeValue: 'shortlisted' },
    { name: 'Dismissed', count: reviewStatuses?.dismissed, changeValue: 'dismissed' }
  ]

  const orderMap = {
    updated_at: 'Latest Activity',
    created_at: 'Created At',
    played_count: 'Listened To Count',
    cached_votes_up: 'Most Upvoted',
    comments_count: 'Most Commented'
  }

  return <>
    <div className='mt-1' />
    <Card title='Project Submissions'>
      <>
        <div ref={scrollRef}>
          <div className='flex justify-between items-center'>
            <Tabs statuses={reviewStatuses} tablist={tablist} currentTab={reviewStatus} changeTab={changeTab} />
            <div className='flex space-x-2 items-center'>
              <div className='relative flex items-center'>
                <button onClick={() => setDropdownOpen(!dropdownOpen)} className='py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-48'>
                  <span className="sr-only" hidden>Open options</span>
                  <SortAscendingIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
                  <span className='truncate'>{orderMap[order]}</span>
                </button>
                { dropdownOpen && <div ref={dropdownRef} tabIndex="-1" className={`absolute right-0 z-30 ${dropdownOpen ? '' : 'hidden opacity-0 scale-0'} py-2 overflow-y-auto transition origin-top-right bg-white border border-gray-900 dark:border-gray-100 rounded-md shadow-md dark:bg-gray-800 top-6 max-h-80`}>
                  <div onClick={() => setOrder('updated_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.updated_at}</div>
                  <div onClick={() => setOrder('created_at')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.created_at}</div>
                  <div onClick={() => setOrder('played_count')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.played_count}</div>
                  <div onClick={() => setOrder('cached_votes_up')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.cached_votes_up}</div>
                  <div onClick={() => setOrder('comments_count')} className='text-gray-700 dark:text-gray-100 flex px-4 py-2 text-sm cursor-pointer hover:text-cccblue-alt dark:hover:text-cccblue-alt' >{orderMap.comments_count}</div>
                </div> }
              </div>
              <select id="tabs" name="tabs" className="py-1 px-2 text-xs sm:text-sm text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-cccblue focus:ring-2 focus:ring-blue-700 focus:text-cccblue dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex space-x-1 items-center w-48" defaultValue={role} onChange={(e) => setRole(e.target.value)}>
                <option value={'all'}>--- Filter Role ---</option>
                {roles.map((pr) => (
                  <option key={`prfilter${pr.name}`} value={pr.id}>{pr.name}</option>
                ))}
              </select>
            </div>
          </div>
          { searchOpen && <div className='flex flex-col sm:flex-row mb-2'>
            <input name='contains' type="text" onChange={handleChange}
              className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by project name or role name. ex: Goku"
            />
          </div> }
          { submissions.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
        </div>
        { user.auditionsCount === 0 && <NoResults /> }
        { loading && <Loading /> }
        <ul className="space-y-3">
          {submissions.map((sub) => (
            <SubmissionRow key={`pubsub${sub.id}${sub.reviewStatus}`} submission={sub} voteIds={[]} />
          ))}
        </ul>
        { submissions.length >= 0 && <div className='mt-2'><Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /></div> }
      </>
    </Card>
  </>
}

export default SubmissionsIndex
