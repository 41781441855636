import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from '@/shared/Card'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'

import TipTapTextarea from '@/shared/TipTapTextarea'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const GeneralForm = ({initSubmission}) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const tipTapIdx = useRef(1)
  const [submission, ] = useState(initSubmission)
  const [note, ] = useState(initSubmission.note)
  const [demos, setDemos] = useState([])
  const [selected, setSelected] = useState(submission.permissions)
  const { putpostRequest, getRequest } = useQuery()
  const { formState, register, unregister, handleSubmit, setValue, setError } = useForm({
    defaultValues: {
      note: submission.note,
      permissions: submission.permissions,
      demo_id: submission.demoId
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('note')
    register('permissions')
    register('demo_id')
    return () => {
      unregister('note')
      unregister('permissions')
      unregister('demo_id')
    }
  }, [register])

  useEffect(() => {
    if (demos.length === 0 && currentUser.canAttachDemo) {
      getRequest('/api/v3/demos', {}, (err, jsonData) => {
        if (err) { return }
        setDemos(jsonData.demos)
        setValue('demo_id', submission?.demoId, { shouldDirty: true, shouldValidate: true })
      })
    }
  }, [])

  useEffect(() => {
    setValue('note', note, { shouldDirty: true, shouldValidate: true })
  }, [note])

  const changePermission = (perm) => {
    setSelected(perm)
    setValue('permissions', perm, { shouldDirty: true, shouldValidate: true })
  }

  const updateSelectedDemoId = (e) => {
    const demo = demos.find((role) => parseInt(role.id, 10) === parseInt(e.target.value, 10))
    setValue('demo_id', demo.id, { shouldDirty: true, shouldValidate: true })
  }

  const goToSubmissionShow = () => {
    window.location.href = `/submissions/${submission.id}`
  }

  const handleUpdateTipTap = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    putpostRequest(`/api/v3/auditions/${submission.id}`, 'PATCH', { audition: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setToast(randomToastSuccess())
    })
  })

  return <div className='mt-6'>
    <Card title="Additional Information"
      footer={
        <div className='flex flex-rows justify-end items-center py-2 gap-x-2'>
          <DefaultButton onClick={goToSubmissionShow} text='Done Editing' />
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
        </div>
      } >
      <form>
        <div className="grid sm:grid-cols-4 gap-6">

          <div className="col-span-4 sm:col-span-4">
            <div className='mt-4'>
              <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Note
              </label>
              <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. This will be shown with your submission.</p>
              <div className="mt-1 shadow-sm w-full min-w-full">
                <TipTapTextarea
                  html={note}
                  idx={tipTapIdx.current}
                  updateHtml={(val) => handleUpdateTipTap('note', val)}
                  placeholder='Please see my CCC profile for more samples. Also, I have a pet iguana.'
                />
              </div>
            </div>
          </div>

          <div className="col-span-4 sm:col-span-4">
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Permissions
            </label>
            <div className="flex flex-col gap-y-2">
              <div className="flex flex-col bg-white dark:bg-gray-900  cursor-pointer select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('public')}>
                <div className="flex justify-between">
                  <div className='flex justify-start gap-x-2'>
                    <EyeIcon className="h-5 w-5" aria-hidden="true" />
                    <p className='font-normal capitalize'>Public</p>
                  </div>
                  { selected === 'public' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
                </div>
                <span className='text-xs text-gray-500 dark:text-gray-200'>This submission will appear on your profile and can be visited by anyone.</span>
              </div>
              <div className="flex flex-col bg-white dark:bg-gray-900 cursor-pointer select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('unlisted')}>
                <div className="flex justify-between">
                  <div className='flex justify-start gap-x-2'>
                    <FingerPrintIcon className="h-5 w-5" aria-hidden="true" />
                    <p className='font-normal capitalize'>Unlisted</p>
                  </div>
                  { selected === 'unlisted' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
                </div>
                <span className='text-xs text-gray-500 dark:text-gray-200'>This submission will not appear on your profile, but can be seen with a direct link</span>
              </div>
              <div className="flex flex-col bg-white dark:bg-gray-900 cursor-pointer select-none relative p-2 text-sm hover:text-cccblue text-gray-900 dark:text-gray-100" onClick={() => changePermission('private')}>
                <div className="flex justify-between">
                  <div className='flex justify-start gap-x-2'>
                    <EyeOffIcon className="h-5 w-5" aria-hidden="true" />
                    <p className='font-normal capitalize'>Private</p>
                  </div>
                  { selected === 'private' && <CheckIcon className="h-5 w-5 text-cccblue" aria-hidden="true" /> }
                </div>
                <span className='text-xs text-gray-500 dark:text-gray-200'>This submission will not appear on your profile and can only be visited by you and the project owner.</span>
              </div>
            </div>
          </div>

          <div className="col-span-4 sm:col-span-4">
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Attach Demo
            </label>
            { currentUser.canAttachDemo && demos.length > 0 && <div className='flex flex-col text-gray-900 dark:text-gray-100 p-2 rounded-md'>
              <p className="text-xs text-gray-500  dark:text-gray-400 mb-1">This will be shown with your submission.</p>
              <select name='select_role' className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm" onChange={updateSelectedDemoId} defaultValue={submission?.demoId}>
                <option value={null}></option>
                {demos.map((demo, idx) => (
                  <option key={`dk${demo.id}`} value={demo.id}>{demo.name}</option>
                ))}
              </select>
            </div> }
            { !currentUser.canAttachDemo && <div className='flex flex-col text-gray-900 dark:text-gray-100 bg-cccblue bg-opacity-20 p-2 rounded-md text-sm'>
              This feature is only available to CCC Premium members.
            </div> }
          </div>

        </div>
      </form>
    </Card>
  </div>
}

export default GeneralForm

GeneralForm.propTypes = {
  initSubmission: PropTypes.object.isRequired
}
