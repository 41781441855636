import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Card from '@/shared/Card'
import AudioUploadForm from '@/shared/AudioUploadForm'
import DefaultButton from '@/shared/Buttons/Default'
import DumbAudioPlayer from '@/shared/DumbAudioPlayer'

const SubmissionAudio = ({ project, submission, selectedRole }) => {
  const objectId = submission?.id
  const [editing, setEditing] = useState(!submission?.audioUrl && !submission?.audioUploadRawUrl)
  const [remoteUrl, setRemoteURL] = useState(submission?.audioUrl || submission?.audioUploadRawUrl)

  const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    setRemoteURL(null)
  }

  const fetchFilePathInfo = (remoteUrl, auditionId) => {
    if (submission?.id) {
      setRemoteURL(remoteUrl)
      setEditing(false)
    } else {
      window.location.href = `/submissions/${auditionId}/edit?success=true`
    }
  }

  return <div className='mt-6'>
    <Card title="Audio Submission">
      <div className="flex justify-center">
        { editing && <div className='flex flex-col w-full justify-center mx-auto'>
          <div className='p-1 mx-auto'>
            <AudioUploadForm
              kind={'Audition'}
              roleId={selectedRole.id}
              objectId={objectId}
              onProcessingStarted={updateFilePathInfo}
              onSuccess={fetchFilePathInfo}
            />
          </div>
          <div className='w-m p-1 mx-auto'>
            { remoteUrl && <DefaultButton className='mt-5' onClick={() => setEditing(false)} text="Preview Audio" /> }
          </div>
        </div> }
        { !editing && <div className='flex flex-col w-full justify-center'>
          <div className='w-full p-1'>
            <DumbAudioPlayer mediaUrl={remoteUrl} />
          </div>
          <div className='w-m p-1 mx-auto'>
            <DefaultButton className='mt-5' onClick={() => setEditing(true)} text="Upload a different file instead" />
          </div>
        </div> }
      </div>
    </Card>
  </div>
}

export default SubmissionAudio

SubmissionAudio.propTypes = {
  project: PropTypes.object.isRequired,
  selectedRole: PropTypes.object.isRequired,
  submission: PropTypes.object
}
